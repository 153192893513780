import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  // 首页
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // 客服端
  {
    path: "/service",
    name: "service",
    component: () => import("../views/serviceView.vue"),

    children: [
      //   {
      //       path: '',
      //       component: () => import('../components/Service/ControlView.vue'),
      // },
      {
        path: "",
        component: () => import("../components/Service/ControlView1/index.vue"),
      },
      {
        path: "AuditManagement",
        component: () => import("../components/Service/ControlView1/AuditManagement.vue"),
      },
      {
        path: "Customerslist",
        component: () => import("../components/Service/ControlView1/Customerslist.vue"),
      },
      {
        path: "setting",
        component: () => import("../components/Service/SettingView.vue"),
      },
      {
        path: "clues",
        component: () => import("../components/Service/CluesView.vue"),
      },
      {
        path: "pause",
        component: () => import("../components/Service/PauseView.vue"),
      },
      {
        path: "salesTable",
        component: () => import("../components/Service/SalesTableView.vue"),
      },
      {
        path: "check",
        component: () => import("../components/Service/CheckView.vue"),
      },
      {
        path: "urgent",
        component: () => import("../components/Service/UrgentView.vue"),
      },
      {
        path: "shelve",
        component: () => import("../components/Service/ShelveView.vue"),
      },
      {
        path: "customershelve",
        component: () => import("../components/Service/CustomerShelveView.vue"),
      },
      {
        path: "debtplanning",
        component: () => import("../components/Service/DebtPlanningView.vue"),
      },
      {
        path: "customerShelveDetail",
        component: () =>
          import("../components/ShelveElement/CustomerShelveDetailView.vue"),
      },
      {
        path: "checkdetail",
        component: () =>
          import("../components/CheckElement/CheckDetailView.vue"),
      },
      {
        path: "management",
        name: "management",
        component: () =>
          import("../components/ManagementElement/ManagementView.vue"),
      },
      {
        path: "shelvedetail",
        name: "shelvedetail",
        component: () =>
          import("../components/ShelveElement/ShelveDatailView.vue"),
      },
      // {
      //     path: 'casedetail',
      //     name: 'casedetail',
      //     component: () => import('../components/RoundElement/CaseDatailView.vue'),
      // },
      {
        path: "detail",
        name: "detail",
        component: () => import("../components/UrgentElement/DetailView.vue"),
      },
      // 调解员客户管理
      {
        path: "lawtotal",
        component: () => import("../components/Service/LawtotalView.vue"),
      },
      {
        path: "lawtotaldetail",
        component: () => import("../components/Customer/Customerdetails.vue"),
      },
      // 法助客户管理
      {
        path: "assistant",
        component: () => import("../components/Service/AssistantView.vue"),
      },
      // 备注跟进
      {
        path: "remarksFollow",
        name: "remarksFollow",
        component: () => import("../components/Service/remarksFollow.vue"),
      },
      // 案件管理
      {
        path: "case",
        component: () => import("../components/Service/CaseView/normal.vue"),
      },
      {
        path: "case/fromconsole",
        component: () =>
          import("../components/Service/CaseView/fromConsole.vue"),
      },
      // 案件管理详情
      {
        path: "casedetail",
        name: "casedetail",
        component: () => import("../components/RoundElement/CaseDatail.vue"),
      },

      // 播报设置-播报目标填报
      {
        path: "target",
        component: () => import("../components/BroadcastElement/Target.vue"),
      },
      // 播报设置-播报口号设置
      {
        path: "slogan",
        component: () => import("../components/BroadcastElement/Slogan.vue"),
      },
      // 播报设置-团队人力填报
      {
        path: "manpower",
        component: () => import("../components/BroadcastElement/Manpower.vue"),
      },
      {
        path: "hdvue",
        component: () => import("../components/HDvue.vue"),
      },
      // 短信管理 === 发送记录
      {
        path: "sendMessage",
        component: () =>
          import("../components/MessageElement/SendMessageView.vue"),
      },
      // 短信管理 === 新建发送
      {
        path: "creatMessage",
        component: () =>
          import("../components/MessageElement/CreatMessageView.vue"),
      }, // 短信记录详情
      {
        path: "msgDetail",
        name: "msgDetail",
        component: () =>
          import("../components/MessageElement/components/msgDetailView.vue"),
      },

      // 销售抢量活动
      {
        path: "seizeQuantityLog",
        name: "seizeQuantityLog",
        component: () => import("../components/SeizeQuantity/Log.vue"),
      },
      // 系统设置--标签管理
      {
        path: "tagset",
        name: "tagset",
        component: () => import("../components/SystemSet/TagSetView.vue"),
      },
      {
        path: "speech",
        name: "speech",
        component: () => import("../components/SystemSet/Speech.vue"),
      },
      {
        path: "Accountmanagement",
        name: "Accountmanagement",
        component: () => import("../components/SystemSet/Accountmanagement.vue"),
      },
      //审核管理
      //案件状态审核
      {
        path: "status",
        name: "status",
        component: () => import("../components/caseStatusElement/status.vue"),
      },
      {
        path: "Auditlists",
        name: "Auditlists",
        component: () => import("../components/caseStatusElement/Auditlists.vue"),
      },
      // 权限管理 === 组织架构管理
      {
        path: "team",
        component: () => import("../components/AuthorityElement/TeamView.vue"),
      },
      // 权限管理 === 账号管理 
      {
        path: "account",
        component: () =>
          import("../components/AuthorityElement/AccountView.vue"),
      },
            // 权限管理 === 组织架构管理 -- 调解员
            {
                path: 'team_mediator',
                component: () => import('../components/AuthorityElement_mediator/TeamView.vue'),

            },
            // 权限管理 === 账号管理 -- 调解员
            {
                path: 'account_mediator',
                component: () => import('../components/AuthorityElement_mediator/AccountView.vue'),
            },
      // 转诉讼列表
      {
        path: "litigation_transfer",
        name: "litigation_transfer",
        component: () =>
          import("../components/caseLitigationElement/litigation_transfer.vue"),
      },
      // 转诉讼列表调解员
      {
        path: "litigationtiaojie",
        name: "litigationtiaojie",
        component: () =>
          import("../components/caseLitigationElement/litigationtiaojie.vue"),
      },
      // 诉讼列表
      {
        path: "litigation",
        name: "litigation",
        component: () =>
          import("../components/LitigationElement/litigation.vue"),
      },
      //今日新增列表
      {
        path: "litigation1",
        name: "litigation1",
        component: () =>
          import("../components/LitigationElement/litigation1.vue"),
      },
      // 诉讼详情
      {
        path: "litigationdetail",
        name: "litigationdetail",
        component: () =>
          import("../components/LitigationElement/litigationdetail.vue"),
      },
      {
        path: "Transfer_review",
        name: "Transfer_review",
        component: () => import("../components/LitigationElement/Transfer.vue"),
      },
      {
        path: "Putthereviewonhold",
        name: "Putthereviewonhold",
        component: () =>
          import("../components/LitigationElement/Putthereviewonhold.vue"),
      },
      {
        path: "team",
        component: () => import("../components/AuthorityElement/TeamView.vue"),
      },
      // 权限管理 === 账号管理
      {
        path: "account",
        component: () =>
          import("../components/AuthorityElement/AccountView.vue"),
      },
      // 待办看台
      {
        path: "todolist",
        component: () =>
          import("../components/TodoList/todolist.vue"),
      },
      {
        path: "todolistdetalis",
        component: () =>
          import("../components/TodoList/todolistdetalis.vue"),
      },
      {
        path: "Assisteddetalis",
        component: () =>
          import("../components/TodoList/Assisteddetalis.vue"),
      },
      {
        path: "chatmanagement",
        component: () =>
          import("../components/Applist/chatmanagement.vue"),
      },
      {
        path: "chatuploads",
        component: () =>
          import("../components/Applist/chatuploads.vue"),
      },
      {
        path: "Credit",
        component: () =>
          import("../components/Applist/Credit.vue"),
      },
      {
        path: "loan",
        component: () =>
          import("../components/Applist/loan.vue"),
      },
      {
        path: "notice_list",
        name: "notice_list",
        component: () =>
          import("../components/NoticeList/list.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "/404",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
